import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { CampeonatosService } from '../../services/campeonatos/campeonatos.service';
import { GlobalVariable } from 'src/app/global';
import { Util } from 'src/app/utils/utils';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ClientesService } from 'src/app/snapquick/services/clientes/clientes.service';
import { RegistroClienteComponent } from 'src/app/base/components/registro-cliente/registro-cliente.component';
import { RolesPartidosService } from '../../services/roles-partidos/roles-partidos.service';
import { Socket } from 'ngx-socket-io';
import { RegistroJugadorComponent } from '../registro-jugador/registro-jugador.component';

declare const PDFDocument:any;
declare const blobStream:any;
declare const qr:any
declare const $:any;
declare const resaltarPestañaMenu:any;
declare const aplicarSubidaArchivo:any;
declare const aplicarVisorImagenes:any;

@Component({
  selector: 'app-campeonatos',
  templateUrl: './campeonatos.component.html',
  styleUrls: ['./campeonatos.component.css']
})
export class CampeonatosComponent extends BaseComponent implements OnInit {

  campeonatos:any[]=[];
  equipos:any[]=[];
  jugadores:any[]=[];
  impresion_tarjetas_jugador:any={lista_jugadores:[],jugador:{}};
  campeonato:any;
  equipo:any;
  tipo_categoria:any;
  tipo_nivel:any;
  tipo_disciplina:any
  searching = false;
  searchFailed = false;
  cliente_registrado:boolean=false;
  razon_busqueda:any;
  modalCliente:NgbModalRef;
  modalJugador:NgbModalRef;

  @ViewChildren('campeonatos') campeonatos_lectura: QueryList<any>;

  modal_campeonato_modificacion:NgbModalRef;
  @ViewChild('modal_campeonato_modificacion')
  private modal_campeonato_modificacion_ref: TemplateRef<any>;

  modal_lista_equipos:NgbModalRef;
  @ViewChild('modal_lista_equipos')
  private modal_lista_equipos_ref: TemplateRef<any>;

  modal_equipo_modificacion:NgbModalRef;
  @ViewChild('modal_equipo_modificacion')
  private modal_equipo_modificacion_ref: TemplateRef<any>;

  modal_lista_jugadores:NgbModalRef;
  @ViewChild('modal_lista_jugadores')
  private modal_lista_jugadores_ref: TemplateRef<any>;

  modal_jugador_modificacion:NgbModalRef;
  @ViewChild('modal_jugador_modificacion')
  private modal_jugador_modificacion_ref: TemplateRef<any>;

  modal_configuracion_tarjeta_jugador:NgbModalRef;
  @ViewChild('modal_configuracion_tarjeta_jugador')
  private modal_configuracion_tarjeta_jugador_ref: TemplateRef<any>;

  modal_configuracion_pdf_rol_partido:NgbModalRef;
  @ViewChild('modal_configuracion_pdf_rol_partido')
  private modal_configuracion_pdf_rol_partido_ref: TemplateRef<any>;

  modal_impresion_tarjetas_jugador:NgbModalRef;
  @ViewChild('modal_impresion_tarjetas_jugador')
  private modal_impresion_tarjetas_jugador_ref: TemplateRef<any>;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    public campeonatosService:CampeonatosService,
    public clientesService:ClientesService,
    public rolesPartidosService:RolesPartidosService,
    private socket: Socket) { 
      super(persistenciaService,modalService,generalService,usuarioService);
    }

  ngOnInit(): void {
    this.init();
		this.buscarAplicacion(this.router.url.substring(1));
		this.configuracion_pagina={};
		this.filter={
			id_empresa:this.usuario.id_empresa
		}
		//this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_RUTAS);

    this.column = "fecha_inicio";
		this.getSearch(this.text_search,null);
  }

  ngAfterViewInit() {
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
    this.campeonatos_lectura.changes.subscribe(t => {
      aplicarVisorImagenes("imagen-campeonato-vista");
    });
  }

  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.campeonatosService.obtenerListaCampeonatos(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.campeonatos=dato.campeonatos;
			this.blockUI.stop();
		});
	}

  abrirConfiguracionAplicacion(){
		
	}

  async crearNuevoCampeonato(){
    let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CAMPEONATO);
    this.campeonato={      
        id_empresa: this.usuario.id_empresa, id_usuario:this.usuario.id,
        codigo:codigo_nuevo,
        activo:true,
        configuracion_tarjeta_jugador:{
          fondo_color_tarjeta:"#06427e",
          titulo_color_liga:"#ffffff",
          titulo_color_jugador:"#ffff00",
          enunciados_color:"#ffffff",
          datos_color:"#ffff00"
        }
    }
    this.abrirRegistroCampeonato();
  }

  abrirRegistroCampeonato(){
    this.modal_campeonato_modificacion=this.modalService.open(this.modal_campeonato_modificacion_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_campeonato_modificacion.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    aplicarSubidaArchivo('dropzone','preview-template',this,'subirArchivo','eliminarArchivo',{maximo_tamano_archivo:10});
    aplicarSubidaArchivo('fondo-tabla','fondo-tabla-template',this,'subirArchivoFondo','eliminarArchivoFondo',{maximo_tamano_archivo:10});
  }

  subirArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.campeonato.logo=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.campeonato.logo=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  subirArchivoFondo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.campeonato.fondo_tabla_posiciones=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivoFondo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.campeonato.fondo_tabla_posiciones=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  validarFormularioRegistroCampeonato(){
		let res=true;
		if(!this.campeonato.nombre){
			this.toastr.error("Debe especificar el nombre del campeonato!");
			res=false;
		}
		if(!this.campeonato.fecha_inicio_texto || !this.campeonato.fecha_inicio_texto.year || !this.campeonato.fecha_inicio_texto.month || !this.campeonato.fecha_inicio_texto.day){
      res=res && false;
      this.toastr.error("Debe especificar la fecha de inicio completa para el campeonato!");
    }
		return res;
	}

	guardarCampeonato(){
		if(this.validarFormularioRegistroCampeonato()){
			this.blockUI.start();
      this.campeonato.fecha_inicio=Util.convertirObjetoAfecha(this.campeonato.fecha_inicio_texto);
      this.campeonato.fecha_fin=this.campeonato.fecha_fin_texto?Util.convertirObjetoAfecha(this.campeonato.fecha_fin_texto):null;
			if(this.campeonato.id){
				this.campeonatosService.actualizarCampeonato(this.campeonato).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success("Campeonato registrado satisfactoriamente");
          this.getItems();
					this.modal_campeonato_modificacion.close();
				});
			}else{
				this.campeonatosService.guardarCampeonato(this.campeonato).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CAMPEONATO);
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
          this.getItems();
					this.modal_campeonato_modificacion.close();
				});
			}
		}
		
	}

  async modificarCampeonato(campeonato){
    this.campeonato=campeonato;
    this.campeonato.fecha_inicio_texto=Util.convertirFechaAObjectoFecha(new Date(this.campeonato.fecha_inicio));
    this.campeonato.fecha_fin_texto=this.campeonato.fecha_fin?Util.convertirFechaAObjectoFecha(new Date(this.campeonato.fecha_fin)):null;
    this.abrirRegistroCampeonato();
  }

  async obtenerCategoriasEquiposEmpresa(){
		this.tipo_categoria=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_EQUIPO_CATEGORIAS).toPromise();
	}

  async obtenerNivelesEquiposEmpresa(){
		this.tipo_nivel=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_EQUIPO_NIVELES).toPromise();
	}

  async obtenerDisciplinasEquiposEmpresa(){
		this.tipo_disciplina=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_EQUIPO_DISCIPLINAS).toPromise();
	}

  async verEquipos(campeonato){
    this.campeonato=campeonato;    
    this.obtenerEquiposCampeonato();
    this.obtenerCategoriasEquiposEmpresa();
    this.obtenerNivelesEquiposEmpresa();
    this.obtenerDisciplinasEquiposEmpresa();
    this.modal_lista_equipos=this.modalService.open(this.modal_lista_equipos_ref, {scrollable:true,windowClass:'lista-equipos',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_lista_equipos.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async crearNuevoEquipo(campeonato){
    this.razon_busqueda=null;
    this.cliente_registrado=false;
    let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_EQUIPO);
    this.equipo={   
        id_empresa:this.usuario.id_empresa,   
        id_campeonato: campeonato.id,
        codigo:codigo_nuevo,
        activo:true,
        cantidad_jugadores:0,
        cliente:{}
    }
    this.abrirRegistroEquipo();
  }

  abrirRegistroEquipo(){
    this.modal_equipo_modificacion=this.modalService.open(this.modal_equipo_modificacion_ref, {scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_equipo_modificacion.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    aplicarSubidaArchivo('logo-equipo','preview-template-logo',this,'subirArchivoLogo','eliminarArchivoLogo',{maximo_tamano_archivo:10});
  }

  validarFormularioRegistroEquipo(){
		let res=true;
		if(!this.equipo.nombre){
			this.toastr.error("Debe especificar el nombre del equipo!");
			res=false;
		}
    if(!this.equipo.nivel){
			this.toastr.error("Debe especificar el nivel del equipo!");
			res=false;
		}
    if(!this.equipo.categoria){
			this.toastr.error("Debe especificar la categoria del equipo!");
			res=false;
		}
    if(!this.equipo.disciplina){
			this.toastr.error("Debe especificar la disciplina del equipo!");
			res=false;
		}
    if(!this.equipo.cliente.razon_social){
			this.toastr.error("Debe especificar el nombre del delegado!");
			res=false;
		}
    if(!this.equipo.cliente.telefono1){
			this.toastr.error("Debe especificar el telefono del delegado!");
			res=false;
		}
		return res;
	}

	guardarEquipo(){
		if(this.validarFormularioRegistroEquipo()){
			this.blockUI.start();
			if(this.equipo.id){
				this.campeonatosService.actualizarEquipo(this.equipo).subscribe((res:any) => {
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
          this.obtenerEquiposCampeonato();
					this.modal_equipo_modificacion.close();
				});
			}else{
				this.campeonatosService.guardarEquipo(this.equipo).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_EQUIPO);
					this.blockUI.stop();
					this.toastr.success(res.mensaje);
          this.obtenerEquiposCampeonato();
					this.modal_equipo_modificacion.close();
				});
			}
		}
		
	}

  obtenerEquiposCampeonato(){
    this.blockUI.start('Recuperando... espere por favor!');
		this.campeonatosService.obtenerListaEquipos(this.campeonato.id,0,0).subscribe((equipos:any) => {
			this.equipos=equipos;
			this.blockUI.stop();
		});
  }

  subirArchivoLogo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.equipo.logo=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  eliminarArchivoLogo(file){
    let me=this;
		var fileToLoad = file;
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
        me.equipo.logo=null;
			}
			fileReader.readAsDataURL(fileToLoad);	
  }

  async modificarEquipo(equipo){
    this.equipo=equipo;
    this.equipo.cliente=equipo.cliente?equipo.cliente:{};  
    this.equipo.id_empresa=this.usuario.id_empresa;
    this.abrirRegistroEquipo();
  }

  async verJugadores(equipo){
    this.equipo=equipo;
    this.obtenerJugadoresEquipo();
    this.modal_lista_jugadores=this.modalService.open(this.modal_lista_jugadores_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_lista_jugadores.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async crearNuevoJugador(equipo){
    this.modalJugador = this.modalService.open(RegistroJugadorComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalJugador.componentInstance.equipo = equipo;
		this.modalJugador.componentInstance.usuario = this.usuario;
		
		this.modalJugador.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.obtenerJugadoresEquipo();
					this.modalJugador.close();
				}
			}else{
				this.modalJugador.close();
			}
			
		});
  }

  obtenerJugadoresEquipo(){
    this.blockUI.start('Recuperando... espere por favor!');
		this.campeonatosService.obtenerListaJugadores(this.equipo.id).subscribe((jugadores:any) => {
			this.jugadores=jugadores;
			this.blockUI.stop();
		});
  }

  async modificarJugador(jugador_a_modificar){
    let jugador:any=jugador_a_modificar;
    jugador.persona.fecha_nacimiento_texto=jugador.persona.fecha_nacimiento?Util.convertirFechaAObjectoFecha(new Date(jugador.persona.fecha_nacimiento)):null;
    jugador.id_empresa=this.usuario.id_empresa;

    this.modalJugador = this.modalService.open(RegistroJugadorComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalJugador.componentInstance.jugador = jugador;
		this.modalJugador.componentInstance.usuario = this.usuario;
		
		this.modalJugador.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.obtenerJugadoresEquipo();
					this.modalJugador.close();
				}
			}else{
				this.modalJugador.close();
			}
		});
  }

  abrirModalGeneracionPdfTarjetasJugador(){
    this.modal_impresion_tarjetas_jugador=this.modalService.open(this.modal_impresion_tarjetas_jugador_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_impresion_tarjetas_jugador.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async generarTarjetaIdentificacion(jugadores_seleccionados){
    var doc = new PDFDocument({layout:'landscape',margin:0,size:GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA});
    var stream = doc.pipe(blobStream());
    let canvas:any=document.getElementById('qr-code');
    // draw some text
    doc.fontSize(8);
    var yCard=20,items=0,page=1,carnets_por_hoja=8,x_c=45;
    let fecha_inicio=new Date();
    let fecha_fin=new Date();
    fecha_fin.setDate(fecha_inicio.getDate()+365-1);
    for(var i=0;i<jugadores_seleccionados.length;i++){
      doc.switchToPage(page-1);
      items++;
      let campeonato=jugadores_seleccionados[i].equipo.campeonato;
      //Carnet Cara Principal
      doc.rect(x_c, yCard, 227, 142).fill(campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
      let logo=await Util.convertirUrlToBase64Image(this.rest_server+campeonato.logo);
      doc.image(logo,x_c+170,yCard,{width: 50});
      doc.fillColor('white');
      doc.font('Helvetica-Bold',11);
      if(campeonato.configuracion_tarjeta_jugador.campeonato_propio){
        doc.text(this.usuario.empresa.nombre,x_c+10,yCard+10,{width:150});
      }else{
        doc.fillColor(campeonato.configuracion_tarjeta_jugador.datos_color);
        doc.text(campeonato.nombre.toUpperCase(),x_c+10,yCard+10,{width:150});
      }
      doc.font('Helvetica-Bold',10);

      doc.fillColor(campeonato.configuracion_tarjeta_jugador.titulo_color_jugador);
      doc.text("TARJETA DE JUGADOR",x_c+30,yCard+42);
      doc.fontSize(8);
      doc.fillColor(campeonato.configuracion_tarjeta_jugador.enunciados_color);
      doc.text("NOMBRE:",x_c+80,yCard+55);
      doc.text("EQUIPO:",x_c+80,yCard+75);
      doc.text("CATEGORIA:",x_c+80,yCard+115);
      doc.text("DISCIPLINA:",x_c+80,yCard+125);
      doc.text("TORNEO:",x_c+80,yCard+95);
      doc.fontSize(8);
      doc.fillColor(campeonato.configuracion_tarjeta_jugador.datos_color);
      let imagen_persona=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+jugadores_seleccionados[i].persona.imagen);
      doc.image(imagen_persona,x_c+9,(yCard+60),{width: 70,height:70});
      doc.text(jugadores_seleccionados[i].persona.nombre_completo.toUpperCase(),x_c+118,yCard+55,{width:100});
      doc.text((this.equipo?this.equipo.nombre:jugadores_seleccionados[i].equipo.nombre),x_c+118,yCard+75,{width:180});
      doc.text((this.equipo?this.equipo.categoria.nombre:jugadores_seleccionados[i].equipo.categoria.nombre),x_c+130,yCard+115,{width:100});
      doc.text((this.equipo?this.equipo.disciplina.nombre:jugadores_seleccionados[i].equipo.disciplina.nombre),x_c+130,yCard+125,{width:100});
      doc.text(campeonato.nombre.toUpperCase(),x_c+118,yCard+95,{width:100});
      doc.rect(x_c+9, yCard+125, 70,5).fill(campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
      doc.fillColor(campeonato.configuracion_tarjeta_jugador.enunciados_color);
      doc.fontSize(7);
      let texto_carnet=jugadores_seleccionados[i].persona.ci?jugadores_seleccionados[i].persona.ci:"";
      let fecha_nacimiento:any=jugadores_seleccionados[i].persona.fecha_nacimiento?new Date(jugadores_seleccionados[i].persona.fecha_nacimiento):null;
      texto_carnet=texto_carnet+(fecha_nacimiento?" "+(fecha_nacimiento.getDate()+"/"+(fecha_nacimiento.getMonth()+1)+"/"+fecha_nacimiento.getFullYear()):"");
      doc.text(texto_carnet,x_c+9,yCard+126,{width:70,align:'center'});
      
      //Carnet Cara trasera
      doc.rect(x_c+228, yCard, 227, 142).fillAndStroke("#ffffff","#000000");
      qr.canvas({
        canvas: canvas,
        value: jugadores_seleccionados[i].codigo
      },function(){console.log("created");});
      var qrImage=canvas.toDataURL('image/png');
      doc.image(qrImage,x_c+360,(yCard+5),{width: 70});	
      //doc.rect(295, yCard+16, 155,85).fillAndStroke("yellow", "#900");
      doc.fillColor('black');
      doc.fontSize(9);
      doc.text("¡Cuanto más dificil es la victoria, mayor es la felicidad de ganar!",x_c+240,yCard+10,{width:125});
      doc.fontSize(6);
      doc.text("-------------------------------------------",x_c+240,yCard+110);
      doc.text("JUGADOR",x_c+240,yCard+120,{width:80,align:"center"});
      doc.text("-------------------------------------------",x_c+340,yCard+110);
      if(campeonato.configuracion_tarjeta_jugador.campeonato_propio){
        doc.text("L.D.C.M. ORGANIZACIÓN",x_c+340,yCard+120,{width:80,align:"center"});
      }else{
        doc.text("ORGANIZACIÓN",x_c+340,yCard+120,{width:80,align:"center"});
      }

      if(items%2==0){
        yCard=yCard+143;
        x_c=45;
      }else{
        yCard=yCard;
        x_c=x_c+228*2;
      }
      
      if(items==carnets_por_hoja){
        items=0;
        yCard=20;
        doc.addPage({layout:'landscape',margin:0,size:GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA});
        page++;
      }
    }
    
    doc.end();

    stream.on('finish', function () {
      var fileURL = stream.toBlobURL('application/pdf');
      var w = window.open(fileURL, '_blank', 'location=no');
      setTimeout(function () {
        w.print();
      }, 500);
    });
  }

  abrirModalConfiguracionTarjetaJugador(campeonato){
    this.campeonato=campeonato;
    this.campeonato.configuracion_tarjeta_jugador=this.campeonato.configuracion_tarjeta_jugador?this.campeonato.configuracion_tarjeta_jugador:{
      fondo_color_tarjeta:"#06427e",
      titulo_color_liga:"#ffffff",
      titulo_color_jugador:"#ffff00",
      enunciados_color:"#ffffff",
      datos_color:"#ffff00"
    }
    this.modal_configuracion_tarjeta_jugador=this.modalService.open(this.modal_configuracion_tarjeta_jugador_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_configuracion_tarjeta_jugador.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  guardarConfiguracionTarjetaJugador(){
    this.blockUI.start();
    this.campeonatosService.actualizarComfiguracionTarjetaJugadorCampeonato(this.campeonato).subscribe((res:any) => {
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
      this.modal_configuracion_tarjeta_jugador.close();
    });
  }

  abrirModalConfiguracionPdfRolPartido(campeonato){
    this.campeonato=campeonato;
    this.campeonato.configuracion_pdf_rol_partido=this.campeonato.configuracion_pdf_rol_partido?this.campeonato.configuracion_pdf_rol_partido:{
      distancia_partidos:30,
      mostrar_categoria:false,
      mostrar_disciplina:false,
      mostrar_escenario:false,
      campeonato_propio:true,
      usar_rol_version:1,
      logo_longitud:25,
      logo_vertical:0     
    }
    this.modal_configuracion_pdf_rol_partido=this.modalService.open(this.modal_configuracion_pdf_rol_partido_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_configuracion_pdf_rol_partido.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  guardarConfiguracionPdfRolPartido(){
    this.blockUI.start();
    this.campeonatosService.actualizarComfiguracionTarjetaJugadorCampeonato(this.campeonato).subscribe((res:any) => {
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
      this.modal_configuracion_pdf_rol_partido.close();
    });
  }

  abrirPopupConfirmacionEliminacionJugador(jugador){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar al jugador?";
		this.popupConfirmacion.componentInstance.data = jugador;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarJugador($e.data);
			}
			this.popupConfirmacion.close();
        });
	}
	  
	eliminarJugador(jugador){
		this.blockUI.start();
		this.campeonatosService.eliminarJugador(jugador).subscribe((dato:any)=>{
			this.blockUI.stop();
			if(dato.tiene_error){
				this.toastr.error(dato.mensaje);
			}else{
        this.obtenerJugadoresEquipo();
				this.toastr.success(dato.mensaje);
			}
		});
	}

  buscarCliente = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.clientesService.buscarClienteNit(this.usuario.id_empresa,term,0)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
  formatter = (x: any) => (x.id?(x.identificacion?x.identificacion:"")+" "+(x.razon_social?x.razon_social:""):"");

  cambiarClienteRegistrado(){
    this.cliente_registrado=!this.cliente_registrado;
  }

  establecerCliente(event){
    let cliente=(event.id || event.nit)?event:event.item;
    this.razon_busqueda=cliente.razon_social;
    this.equipo.cliente=cliente;
  }

  reiniciarCliente(){
    this.equipo.cliente={};
    this.razon_busqueda=null;
  }

  abrirPopupRegistroRapidoCliente(){
    this.modalCliente = this.modalService.open(RegistroClienteComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalCliente.componentInstance.cliente = JSON.parse(JSON.stringify(this.equipo.cliente));
    this.modalCliente.componentInstance.sucursales = [];
    this.modalCliente.componentInstance.sucursal = null;
		this.modalCliente.componentInstance.usuario = this.usuario;
		
		this.modalCliente.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.establecerCliente(res.cliente);
					this.modalCliente.close();
				}
			}else{
				this.modalCliente.close();
			}
			
		});
  }

  buscarJugador = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.campeonatosService.buscarJugador(this.campeonato.id,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)

  buscarJugadorEmpresa = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.campeonatosService.buscarJugadorEmpresa(this.usuario.id_empresa,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)

  formatterJugador = (x: any) => (x.id?(x.persona.nombre_completo?x.persona.nombre_completo:""):"");

  establecerJugador(event){
    let jugador=(event.id)?event:event.item;
    this.impresion_tarjetas_jugador.jugador_texto=jugador.persona.nombre_completo;
    this.impresion_tarjetas_jugador.jugador=jugador;
  }

  reiniciarJugador(){
    this.impresion_tarjetas_jugador.jugador={};
    this.impresion_tarjetas_jugador.jugador_texto=null;
    $("#jugador_texto").val("");
  }

  agregarJugadorImpresion(){
    this.impresion_tarjetas_jugador.lista_jugadores.push(this.impresion_tarjetas_jugador.jugador);
    this.reiniciarJugador();
  }

  eliminarJugadorImpresion(jugador){
    this.impresion_tarjetas_jugador.lista_jugadores.splice(this.impresion_tarjetas_jugador.lista_jugadores.indexOf(jugador),1);
  }

  async enviarPendientesEconomicosEquipo(equipo){
    this.blockUI.start();
    let detalles_ventas_pendientes:any=await this.rolesPartidosService.obtenerDetallesVentaPendientes(equipo.cliente.id).toPromise();
    if(detalles_ventas_pendientes.length>0){
      let mensaje="🙋🏻‍♂️ Apreciado Delegado de *"+equipo.nombre+"*! 😔\n\n💴 Le informamos sobre el estado de pagos pendientes al Campeonato "+this.campeonato.nombre+":\n\n";
      let total=0;
      for(let i=0;i<detalles_ventas_pendientes.length;i++){
        let fecha_venta=new Date(detalles_ventas_pendientes[i].venta.fecha);
        let texto=detalles_ventas_pendientes[i].producto.nombre+(detalles_ventas_pendientes[i].descripcion?" "+detalles_ventas_pendientes[i].descripcion:"")+" "+(fecha_venta.getDate()+"/"+(fecha_venta.getMonth()+1)+"/"+fecha_venta.getFullYear());
        let descripcion="❓ "+"Bs "+detalles_ventas_pendientes[i].venta.saldo.toFixed(2)+" "+texto;
        mensaje=mensaje+descripcion+"\n";
        total=total+detalles_ventas_pendientes[i].venta.saldo;
      }

      mensaje=mensaje+"\n 💵 Total Bs "+total.toFixed(2);
      let envio:any={
        telefono_whatsapp:equipo.cliente.telefono1,
        mensaje:mensaje,
        sucursal:{id:this.usuario.sucursalesUsuario[0].sucursal.id}
      };
      this.socket.emit('enviarKardexEconomicoEstudianteWhatsapp',envio);
      this.toastr.success("Se envio el mensaje por whatsapp satisfactoriamente!");
    }else{
      this.toastr.info("El equipo no tiene deudas pendientes!");
    }
    this.blockUI.stop();
  }
}
