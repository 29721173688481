<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Jugador</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarJugador()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <label>Código</label>
        </div>
        <div class="col-sm-12 col-md-6">
            <input [disabled]="codigo_automatico" required type="text" id="codigo" name="codigo" [(ngModel)]="jugador.codigo" placeholder="Código" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Nombre</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="nombre" required type="text" [(ngModel)]="jugador.persona.nombre_completo" placeholder="Nombre" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>C.I.</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="ci" required type="text" [(ngModel)]="jugador.persona.ci" placeholder="C.I." class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Fecha de Nacimiento</label>
        </div>
        <div class="col-sm-12 col-md-9">
          <div class="input-group">
            <input name="fecha_nacimiento_texto" class="form-control" placeholder="dd/mm/yyyy"
                    name="dp" [(ngModel)]="jugador.persona.fecha_nacimiento_texto" ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
            </div>
        </div> 
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Posición</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="posicion" required type="text" [(ngModel)]="jugador.posicion" placeholder="Posición" class="form-control" />
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Foto</label>
        </div>
        <div class="col-sm-12 col-md-9">
          <img *ngIf="jugador.persona.imagen && jugador.persona.imagen.length<100" [src]="rest_server+jugador.persona.imagen" width="150" />
          <img *ngIf="jugador.persona.imagen && jugador.persona.imagen.length>100" [src]="jugador.persona.imagen" width="150" />
          <input type="file" (change)="fileChangeEvent($event)" />
        </div>
    </div>
    <hr>
      <div class="row">
        <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="1/1"
            format="png"
            output="base64"
            resizeToWidth="150"
            resizeToHeight="150"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </div>
</div>