import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampeonatosComponent } from './components/campeonatos/campeonatos.component';
import { EscenariosComponent } from './components/escenarios/escenarios.component';
import { BaseModule } from '../base/base.module';
import { FormsModule } from '@angular/forms';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RolPartidosComponent } from './components/rol-partidos/rol-partidos.component';
import { OrderModule } from 'ngx-order-pipe';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RegistroJugadorComponent } from './components/registro-jugador/registro-jugador.component';



@NgModule({
  declarations: [
    CampeonatosComponent,
    EscenariosComponent,
    RolPartidosComponent,
    RegistroJugadorComponent
  ],
  imports: [
    CommonModule,
    BaseModule,
    FormsModule,
    OrderModule,
    NgbModule,
    NgxBootstrapMultiselectModule,
    ImageCropperModule
  ]
})
export class TorneoModule { }
